<script>
	import Vue from 'vue';
	import GlobalVue from '../helper/Global.vue'
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import facebookLogin from 'facebook-login-vuejs';
	import swal from 'sweetalert';
	import GoogleLogin from 'vue-google-login';
	// import GSignInButton from 'vue-google-signin-button'

	export default {
		extends: BaseVue,
		components: {
			facebookLogin,GoogleLogin
		},
		data() {
			return {
				personalID: '',
				email: '',
				name: '',
				input: {},
				params: {
                	clientId: '228499823754-glc0vf2m8l5gmhk4odsbgkunedciis4o.apps.googleusercontent.com'
				},
				renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                }
			}
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.register
			}
		},
		async mounted() {
			await this.ready();
			
    		await Gen.loadScript("https://apis.google.com/js/api:client.js")
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
				$("label[error]").hide()
				$("form").submit(function () {
					setTimeout(() => {
						$("form label[error]").show();
					}, 2200);
				});
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});
			if(Gen.userToken() && this.user) this.$router.push({name:'Dashboard'})
			this.getApi()
		},
		methods: {
			getApi() { this.$root.topProgress.done() },
			onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest('/login', this.input, (err, resp) => {
					if(e) e.btnUnloading()
					if (err) return Gen.info(err.resp.message, 'warning')

					if (this.input.remember) {
						Gen.putStorage("fotk", resp.token)
						Gen.putCookie("fotk", resp.token)
					} else {
						Gen.putCookie("fotk", resp.token)
						Gen.putCookie("fo_user", resp.user)
					}
					this.$root.user = resp.user
					Gen.info(resp.message, 'success', 500).then(()=>{
						this.$router.push({name:"Dashboard"})
					})
				}, 'POST')
			},
			onSuccess(googleUser) {
				let profile = googleUser.getBasicProfile();
				let id = profile.getId()
				// let id_token = googleUser.getAuthResponse().id_token;
				this.onSubmitGoogle(id)
			},
			onFailure(){

			},
			onSubmitFb(id) {
				Gen.apirest('/login-facebook', {
					id: id
				}, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					Gen.putCookie("fotk", resp.token)
					Gen.putCookie("fo_user", resp.user)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			onSubmitGoogle(id) {
				Gen.apirest('/login-google', { id: id }, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					Gen.putCookie("fotk", resp.token)
					Gen.putCookie("fo_user", resp.user)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			getUserData() {
				FB.login((response)=> {
					if (response.authResponse) {
						console.log('Welcome!  Fetching your information.... ');
						FB.api('/me',  (response)=> {
							// console.log('Good to see you, ' + response.name + '.');
							this.onSubmitFb(response.id)
						});
					} else {
						console.log('User cancelled login or did not fully authorize.');
					}
				});
			},
			sdkLoaded(payload) {
				this.isConnected = payload.isConnected
				this.FB = payload.FB
				if (this.isConnected) this.getUserData()
			},
			onLogin() {
				this.isConnected = true
				this.getUserData()
			},
			onSignIn(){

			},
			onLogout() {
				this.isConnected = false
			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section notopmargin nobottommargin wall-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-7 col-lg-5">
						<div class="box-el login-form">
							<div class="text-center mb-3">
								<h4>{{web.lbl_signin_capital}}</h4>
								<span>{{web.lbl_signin_to_manage}}</span>
							</div>
							<VForm @resp="onSubmit">
								<div class="info"></div>
								<div class="form-group">
									<label for="">{{web.lbl_email}}</label>
									<input name="email" onkeydown="return emailKey(event)" type="email"
										v-model="input.email" :placeholder="web.lbl_email" class="form-control" v-bind="validation('email')">
									<LabelError name="email"></LabelError>
								</div>
								<div class="form-group mb-3 view_password">
									<label for="">{{web.lbl_password}}</label>
									<input name="password" type="password" id="password" class="form-control"
										v-model="input.password" v-bind="validation('password')" :placeholder="fields.password">
										<div class="togglepass">
											<i class="btn_view icon-eye-close"></i>
										</div>
									<LabelError name="password"></LabelError>
								</div>
								<div class="form-row">
									<div class="col-md-7">
										<input id="checkbox-11" class="checkbox-style" name="checkbox-11"
											type="checkbox" v-model="input.remember">
										<label for="checkbox-11" class="checkbox-style-3-label"><span>{{web.lbl_keep_signin}}</span></label>
									</div>
									<div class="col-md-5">
										<p class="text-right">
											<router-link :to="{name:'ForgotPassword'}" href="javascript:;">{{web.lbl_forgot_password}}</router-link>
										</p>
									</div>
								</div>
								<div class="form-group text-center mb-0">
									<button type="submit" class="button button-red btn-loading">{{web.lbl_signin}}</button>
								</div>
							</VForm>
							<div class="divider divider-center divider-short mb-3 mt-4">
								<strong>{{web.lbl_or}}</strong>
								
								
							</div>
							<div class="text-center d-flex justify-content-center">
								<GoogleLogin class="social-icon si-rounded si-google i-google nopadding text-center" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
									<i class="icon-google"></i>
									<i class="icon-google"></i>
								</GoogleLogin>
								<facebook-login class="mb-3 mx-0 w-auto pr-0" appId="1147877025559613" @login="getUserData" loginLabel="">
								</facebook-login>
							</div>
							<div class="text-center">
								<h4>
									<router-link :to="{name:'Register'}">{{web.lbl_create_new_account}}</router-link>
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>